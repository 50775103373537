<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row align-items-center">
            <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
                <div class="page-header">
                    <h4 class="page-title">Settings</h4>
                </div>
            </div>
            <!-- <div class="col-lg-5 ms-auto">
                <div class="d-md-flex d-grid gap-3 justify-content-md-end">
                    <button v-if="tabIndex == 0" class="col-lg-2 btn btn-gradient-primary btn-fw" @click=openNextScreen()>Add Room</button>
                    <button v-if="tabIndex == 1" class="col-lg-2 btn btn-gradient-primary btn-fw"@click=openNextScreen()>Add Ward</button>
                </div>
            </div> -->
          </div>
          </br>
          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                  <div class="row">
                    <div>
                      <b-tabs content-class="mt-1" small card v-model="tabIndex">
                        <b-tab title="General" :title-link-class="linkClass(0)" active>
                          <GeneralSettings />
                        </b-tab>
                        <b-tab title="Web Configurations" :title-link-class="linkClass(1)">
                          <WebConfigurations />
                        </b-tab>
                        <b-tab title="Web Page" :title-link-class="linkClass(2)">
                          <WebPage />
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import GeneralSettings from "./general_settings.vue";
import WebConfigurations from "./settings_web_config.vue";
import WebPage from "./settings_webpage.vue";
import { mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"

export default {
  name: 'settings',
  title: string.PAGE_TITLE_SETTINGS,
  components: {
    NavBar,
    Footer,
    SideBar,
    Multiselect,
    GeneralSettings,
    WebConfigurations,
    WebPage
  },
  data() {
    return {
        tabIndex: 0
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions("hospital", ["getRoomListData"]),
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['btn-gradient-primary m-1', 'text-light']
            } else {
                return ['btn-gradient-secondary', 'text-black']
            }
        },

        openNextScreen() {
            if(this.tabIndex == 0) {
              this.$router.push({ name: 'addroom' });
            } else {
              this.$router.push({ name: 'addward' });
            }
        }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
