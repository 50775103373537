<template>
    <div class="row">

        <div class="col-md-12 mt-4 text-center" v-if="is_btn_spinner">
            <b-spinner></b-spinner>
        </div>

        <div class="col-lg-12 text-center" v-if="errorMessage">
            <br/><br/><br/>
            <small class="text-muted">{{ errorMessage }}</small>
            <br/><br/><br/>
        </div>

        <form @submit.prevent="submitForm">
            <div v-for="(section, sectionName) in web_page_sections" :key="sectionName">
                <h4 class="card-title mb-3">{{ sectionName.charAt(0).toUpperCase() + sectionName.slice(1) }}</h4>
                <div v-for="(field, index) in section" :key="index" class="form-group row">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-12">
                            <label :for="field.key">{{ field.label_backend }}</label>
                        </div>
                         <!-- Dynamic Input Field -->
                         <div class="col-md-6 col-lg-6 col-12">
                             <template v-if="field.type === 'text'">
                                <input
                                    v-model="formData[sectionName][field.key]"
                                    :type="field.type"
                                    :id="field.key"
                                    :placeholder="field.label_backend"
                                    class="form-control"
                                />
                            </template>

                            <template v-if="field.type === 'image'">
                                <input
                                    v-if="field.value == ''"
                                    :id="field.key"
                                    class="form-control"
                                    type="file"
                                    @change="handleFileChange($event, sectionName, field.key)"
                                />
                                <div v-if = "formData[sectionName][field.key]" class="col-lg-5 image-web-page">
                                    <img :src="formData[sectionName][field.key]" alt="Preview" style="max-width: 100px; margin-top: 10px;" />
                                    <div class="img-top-right" v-if = "field.value">
                                        <button type="button" @click="deleteImage(field.key, sectionName, formData[sectionName][field.key], -1)" class="btn btn-outline-danger btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                                    </div>
                                </div>
                            </template>

                            <template v-if="field.type === 'boolean'">
                                <div class="switch-container">
                                    <input
                                        v-model="formData[sectionName][field.key]"
                                        type="checkbox"
                                        :id="field.key"
                                        class="switch-checkbox">
                                    <label :for="field.key" class="switch-label"></label>
                                </div>
                            </template>

                            <template v-if="field.type === 'list'">
                                <div v-if="sectionName === 'services'">
                                    <div class="form-group row" v-for="(input, k) in services" :key="k">
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0 mb-md-0">
                                                <input
                                                    v-model="input.title"
                                                    type="text"
                                                    placeholder="Service Title"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0 mb-md-0">
                                                <textarea
                                                    v-model="input.description"
                                                    placeholder="Service Description"
                                                    class="col-12 col-md-3 col-lg-3 form-control"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <input
                                                    v-if="!input.image.startsWith('http')"
                                                    class="form-control"
                                                    type="file"
                                                    @change="handleFileChangeForServices($event, 'services', k)"
                                                />
                                                <div v-if = "input.image" class="col-lg-5 image-web-page">
                                                    <img :src="input.image" alt="Preview" style="max-width: 100px; margin-top: 10px;" />
                                                    <div class="img-top-right" v-if = "input.image">
                                                        <button type="button" @click="deleteImage(field.key, sectionName, input.image, k)" class="btn btn-outline-danger btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <span class="col-12 col-lg-1 mb-2 mb-lg-0 mb-md-0">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    @click="addServiceField()"
                                                    v-show="k === services.length - 1"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    @click="removeServiceField(k)"
                                                    v-show="services.length > 1"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    class="ml-2 cursor-pointer">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="#EC4899" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="sectionName === 'fun_facts'">
                                    <div class="form-group row" v-for="(input,k) in fun_facts" :key="k">
                                        <div class="row">
                                            <div class="col-12 col-md-5 col-lg-5 mb-2 mb-lg-0 mb-md-0">
                                                <input
                                                    v-model="input.title"
                                                    :type="text"
                                                    placeholder="Fun Facts Title"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-12 col-md-5 col-lg-5 mb-2 mb-lg-0 mb-md-0">
                                                <input
                                                    v-model="input.value"
                                                    :type="text"
                                                    placeholder="Value"
                                                    class="col-12 col-md-3 col-lg-3 form-control"
                                                />
                                            </div>
                                            <span class="col-12 col-lg-1 mb-2 mb-lg-0 mb-md-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" @click="addFunFactsField()" v-show="k == fun_facts.length-1" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" /></svg>
                                                <svg xmlns="http://www.w3.org/2000/svg"  @click="removeFunFactsField(k)" v-show="k || ( !k && fun_facts.length > 1)" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="#EC4899" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="sectionName === 'patients'">
                                    <div class="form-group row" v-for="(input,k) in patients" :key="k">
                                        <div class="row">
                                            <div class="col-12 col-md-4 col-lg-4 mb-2 mb-lg-0 mb-md-0">
                                                <input
                                                    v-model="input.name"
                                                    :type="text"
                                                    placeholder="Patients Name"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-12 col-md-4 col-lg-4 mb-2 mb-lg-0 mb-md-0">
                                                <input
                                                    v-model="input.desc"
                                                    :type="text"
                                                    placeholder="Description"
                                                    class="col-12 col-md-3 col-lg-3 form-control"
                                                />
                                            </div>
                                            <div class="col-12 col-md-4 col-lg-4 mb-2 mb-lg-0 mb-md-0">
                                                <input
                                                    v-model="input.role"
                                                    :type="text"
                                                    placeholder="Doctor Designations"
                                                    class="col-12 col-md-3 col-lg-3 form-control"
                                                />
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <input
                                                    v-if="!input.image.startsWith('http')"
                                                    class="form-control"
                                                    type="file"
                                                    @change="handleFileChangeForServices($event, 'patients', k)"
                                                />
                                                <div v-if = "input.image" class="col-lg-5 image-web-page">
                                                    <img :src="input.image" alt="Preview" style="max-width: 100px; margin-top: 10px;" />
                                                    <div class="img-top-right" v-if = "input.image">
                                                        <button type="button" @click="deleteImage(field.key, sectionName, input.image, k)" class="btn btn-outline-danger btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <span class="col-12 col-lg-1 mb-2 mb-lg-0 mb-md-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" @click="addPatientsField()" v-show="k == patients.length-1" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" /></svg>
                                                <svg xmlns="http://www.w3.org/2000/svg"  @click="removePatientsField(k)" v-show="k || ( !k && patients.length > 1)" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="#EC4899" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                         </div>
                    </div>
                </div>
            </div>
            <!-- Submit Button -->
             <div class="col-md-12 mt-4">
                <b-spinner v-if="is_api_call_running" label=""></b-spinner>
                <button v-else type="submit" class="btn btn-gradient-primary me-2">Submit</button>
             </div>
        </form>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import string from "../../constant/strings.js"
import Multiselect from 'vue-multiselect';
import {set_user_settings } from '../../helper/helper';

export default {
    name:'settings',
    title: string.PAGE_TITLE_SETTINGS,
    components: {
      NavBar,
      Footer,
      Multiselect,
      SideBar
    },
    data() {
        return {
          is_btn_spinner:false,
          is_api_call_running:false,
          image_key:false,
          errorMessage:'',
          web_page_sections:{},
          formData: {},
          services: [],
          patients: [],
          fun_facts: [],
        }
    },
  mounted(){
    this.getWebSettings();
  },
  methods:{
    ...mapActions('hospital',['getWebSettingsData', 'updateWebSettingsData', 'deleteWebImageData']),
    getWebSettings() {
        this.is_btn_spinner = true;
        this.getWebSettingsData().then((response) => {
            this.is_btn_spinner = false;
            if (response.response_code == 200) {
                // Initialize formData with values from fields
                 Object.keys(response.data).forEach(section => {
                    this.$set(this.formData, section, {});

                    response.data[section].forEach(field => {
                        this.$set(this.formData[section], field.key, field.value);
                        if (field.type == 'list') {
                            if (section == "services") {
                                if (field.value && field.value.length > 0) {
                                    this.services = field.value
                                } else {
                                    this.addServiceField()
                                }
                            } else if (section == "patients") {
                                if (field.value && field.value.length > 0) {
                                    this.patients = field.value
                                } else {
                                    this.addPatientsField()
                                }
                            } else if (section == "fun_facts") {

                                if (field.value && field.value.length > 0) {
                                    this.fun_facts = field.value
                                } else {
                                    this.addFunFactsField()
                                }
                            }
                        }
                    });

                 });
                this.web_page_sections = response.data;
            } else {
                this.errorMessage = response.message
            }
        })
    },
    handleFileChange(event, sectionName, key) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                this.$set(this.formData[sectionName], key, reader.result);  // Store the image URL in formData
            };
            reader.readAsDataURL(file);
        }
    },
    handleFileChangeForServices(event, type, index) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                if (type == 'services') {
                    this.services[index]['image'] = reader.result
                } else if (type == 'patients') {
                    this.patients[index]['image'] = reader.result
                }
            };
            reader.readAsDataURL(file);
        }
    },
    submitForm() {
        var bodyFormData = new FormData();

        Object.keys(this.formData).forEach(section => {
            Object.keys(this.formData[section]).forEach(field => {
                var fieldValue = this.formData[section][field]
                if (fieldValue && typeof fieldValue === 'string' && fieldValue.startsWith("data:image")){
                    const base64Image = this.formData[section][field];
                    const fileBlob = this.base64ToBlob(base64Image, 'image/jpeg'); // 'image/jpeg' should be dynamically determined from base64 prefix
                    bodyFormData.append(field, fileBlob, field + '.jpg');
                }

                if (section == 'services') {
                    this.formData[section]['services'] = this.services
                } else if (section == 'fun_facts') {
                    this.formData[section]['dyanmic_count'] = this.fun_facts
                } else if (section == 'patients') {
                    this.formData[section]['patients'] = this.patients
                }
             });
        })

        if (this.services.length > 0){
            this.services.forEach((field, index) => {
                if (field['image'] && field['image'].startsWith("data")) {
                    const base64Image = field['image'];
                    const fileBlob = this.base64ToBlob(base64Image, 'image/jpeg'); // 'image/jpeg' can be dynamically set
                    bodyFormData.append('services['+index+']', fileBlob, 'services_' + index + '.jpg'); // Include index in filename
                }
            });
        }

        if (this.patients.length > 0){
            this.patients.forEach((field, index) => {
                if (field['image'] && field['image'].startsWith("data")) {
                    const base64Image = field['image'];
                    const fileBlob = this.base64ToBlob(base64Image, 'image/jpeg'); // 'image/jpeg' can be dynamically set
                    bodyFormData.append('patients['+index+']', fileBlob, 'patients_' + index + '.jpg'); // Include index in filename
                }
            });
        }

        console.log("Form submitted with data: ", this.formData);
        bodyFormData.append('web_settings', JSON.stringify(this.formData));

        this.is_api_call_running = true;
        this.updateWebSettingsData(bodyFormData).then((response) => {
            this.is_api_call_running = false;
            if (response.response_code == 200) {
                this.$toasted.success(response.message, {duration: 2000,});
            } else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
        });
    },
    base64ToBlob(base64, mimeType) {
      const byteCharacters = atob(base64.split(',')[1]); // Remove the "data:image/*;base64," part
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mimeType });
    },
    deleteImage(image_key, sectionName, image_name, index) {
        var bodyFormData = new FormData();
        bodyFormData.append('image_key', image_key);
        bodyFormData.append('image_name', image_name);
      this.img_progress = true;
      this.deleteWebImageData(bodyFormData).then((response) => {
        this.img_progress = false;
        if (response.response_code == 200) {
            if (sectionName != "services" & sectionName != "patients"){
                this.$set(this.formData[sectionName], image_key, '');
                this.web_page_sections[sectionName].forEach(field => {
                    field.value = ''
                })
            } else if(image_key == 'services'){
                this.services[index]['image'] = ''
            } else if(image_key == 'patients'){
                this.services[index]['image'] = ''
            }
            this.$toasted.success(response.message, {duration: 2000,});
        } else{
          this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },
    addServiceField() {
        this.services.push({'image':'', 'title':'', 'description':''})
    },
    removeServiceField(index){
        this.services.splice(index, 1);
    },
    addFunFactsField() {
        this.fun_facts.push({'title':'', 'value':''})
    },
    removeFunFactsField(index){
        this.fun_facts.splice(index, 1);
    },
    addPatientsField() {
        this.patients.push({'image': '', 'name': '',  'desc': '',   'role': '', })
    },
    removePatientsField(index){
        this.patients.splice(index, 1);
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>